import { React, useState, useEffect } from "react";
import {
  Loading,
  Input,
  Upload,
  CalendarPicker,
  Button,
  Notification,
  toaster,
  Panel,
  TextArea,
  Select,
  Badge
} from "@appkit4/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { getrequest,postrequest } from "../../services/global.service";
import * as XLSX from 'xlsx';
import {Buffer} from 'buffer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultErrors = {
  surveyNameError: false,
  surveyDescriptionError: false,
  fileError: false,
};

const UploadSurvey = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [isEdit, setIsEdit] = useState(false);

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });
  const [surveyNames, setSurveyNames] = useState([]);
  const items = [];
  const [filteredItems,setFilteredItems] = useState([items]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [surveydetails, setSurveydetails] = useState({
    expiryDate: undefined,
    surveyDescription: "",
    surveyName: "",
    surveyId: "",
  });
  const [errors, setErrors] = useState(defaultErrors);

  const [search,setSearch] = useState('');

  const [rows, setRows] = useState([]);
  const [selectedDropdownValue,setSelectedDropdownValue]= useState(null);




  useEffect(() => {
    setIsLoading(true);
    if (location.state != null) {
      setIsEdit(true);
      let surveyDetailsLocation = {
        surveyName: location.state.surveyName,
        surveyDescription: location.state.surveyDescription,
        surveyId: location.state.surveyid,
        expiryDate: location.state.expiryDate
          ? new Date(location.state.expiryDate)
          : undefined,
      };
      setSurveydetails(surveyDetailsLocation);
    }
    const fetchSurveyNames = async () => {
      const names = await getAllSurveys();
      console.log(names);
      const qsNames = names.map(item => item.surveyName);
      console.log(qsNames);
      setSurveyNames(qsNames);
      setFilteredItems(qsNames);
      setIsLoading(false);
    };
    fetchSurveyNames();
  }, [location.state]);

  const getAllSurveys = async () => {
    try {
      const response = await getrequest(`${baseURL}/api/Survey/GetSurveyList`, {
        params: { teamName: JSON.parse(Cookies.get("seletedTeam")).name },
      });
      const data = JSON.parse(response.data.data);
      console.log(data);
      setRows(data);
      return data;
    }
    catch(error) {
        navigate("/service-problem");
        return [];
      };
  }

  const userinfo = JSON.parse(Cookies.get("userdetails"));

  const fileHandler = (file) => {
    setSelectedFile(file);
  };

  const cancelClick = () => {
    navigate(-1);
  }

  const validate = () => {
    setErrors((prevState) => ({
      ...prevState,
      fileError: !selectedFile ? true : false,
      surveyNameError: surveydetails.surveyName === "" ? true : false,
      surveyDescriptionError:
        surveydetails.surveyDescription === "" ? true : false,
    }));
    if (
      !selectedFile ||
      surveydetails.surveyName === "" ||
      surveydetails.surveyDescription === ""
    ) {
      return true;
    }
    return false;
  };

  const convertBase64ToBlob = (base64) => {
    const byteCharacters = Buffer.from(base64, 'base64').toString('binary');
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  };
 
  // reading the excel files
  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array'});
        resolve(workbook);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const compareExcelFiles = async(file1, file2) => {
    try{
      
      const workbook1 = await readExcelFile(file1);
      const workbook2 = await readExcelFile(file2);

      const sheet1 = workbook1. Sheets[workbook1.SheetNames[0]];
      const sheet2 = workbook2. Sheets[workbook2.SheetNames[0]];
    
      const csv1 = XLSX.utils.sheet_to_csv(sheet1);
      const csv2 = XLSX.utils.sheet_to_csv(sheet2);

      const rows1 = csv1.split('\n').map(row => row.trim()).filter(row => row.split(',').some(cell => cell.trim() !==''));
      const rows2 = csv2.split('\n').map(row => row.trim()).filter(row => row.split(',').some(cell => cell.trim() !==''));

      console.log("Rows in file 1 filtered ", rows1[0]);
      console.log("Rows in file 2 filtered ", rows2[0]);

      const originalRowCount = rows1.length;
      const newRowCount = rows2.length;

      console.log("originalRowCount ", originalRowCount);
      console.log("newRowCount ", newRowCount);

      const modifiedRows = rows1.filter(row => !rows2.includes(row));
      const unchangedRows = rows1.length - modifiedRows.length

      console.log(modifiedRows.length)
      console.log(unchangedRows.length)

      const additionalRowsCount = rows1.length-rows2.length;
      console.log("Additional Rows Count", additionalRowsCount);
      // console.log("Additional Rows count", additionalRows.length);

      const rows1Common = rows1.slice(0,-additionalRowsCount);
      
      console.log('rows1Common ', rows1Common[0]);
      // console.log("rows2  :",rows2)

      let isModified = false;

      let matched = true;
      if(rows1Common.length !== rows2.length) {
        matched = false;
      }
      else{
        for(let i=0; i< rows2.length;i++){
          if(rows1Common[i] !== rows2[i]){
            matched=false;
            break;
          }
        }      
      }

      if(matched){
        isModified = false;
      }
      else{
        isModified = true;
      }

      
      const hasAdditions = additionalRowsCount > 0;

      return {isModified, hasAdditions};

    }
    catch(error){
      displayAlert("Error during comparison. Please select a different File");
      return {isModified: false, hasAdditions: false};
    } 
  };

  const readExcelFileContent = async(file)=>{
    const workbook = await readExcelFile(file);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const csvContent = XLSX.utils.sheet_to_csv(sheet);
    return csvContent
  }

  const submitSurvey = async (event) => {
    event.preventDefault();
    if (validate()) {
      displayAlert("Please upload file");
      return false;
    } 
    else {
      const formData = new FormData();
      if (selectedFile && selectedFile.originFile) {
        formData.append("File", selectedFile.originFile);
      }
      else{
        console.log("No file selected");
        displayAlert("Please upload file");
        return;
      }
      
      formData.append("surveyName", surveydetails.surveyName);
      formData.append("surveyDescription", surveydetails.surveyDescription);
      formData.append("createdBy", userinfo.email);
      formData.append("teamName", JSON.parse(Cookies.get("seletedTeam")).name);

      if (isEdit) {
        formData.append("versionFlag", 1);
        formData.append("expiryDate", surveydetails.expiryDate);
      } 
      else {
        formData.append("versionFlag", 0);
        formData.append(
          "expiryDate",
          surveydetails.expiryDate
            ? moment(surveydetails.expiryDate).format("DD-MM-YYYY")
            : ""
        );
      }

      console.log("Selected Dropdown value before comparison : ", selectedDropdownValue);
      

      if(selectedDropdownValue){
  
        const selectedSurvey = rows.find(row => row.surveyName === selectedDropdownValue);
        console.log("selected survey ", selectedSurvey);
      
        if (selectedSurvey) {
          const newFileContent = selectedSurvey.newFileContent;
          const originalFileBlob = convertBase64ToBlob(newFileContent);

          console.log("inside the submit comparison stage");
          console.log("inside selected survey");
          console.log(originalFileBlob);
          console.log("originalFileBlob sorted")


          const{ isModified, hasAdditions } = await compareExcelFiles(selectedFile.originFile, originalFileBlob);

          console.log("comparison data   ", {isModified, hasAdditions})
          if (isModified){
            displayAlert("Past data cannot be changed");
            console.log(isModified);
            return;
          }
          else if (hasAdditions){
            console.log(hasAdditions)
            console.log("Additional rows found");
          }
          else{
              console.log("No changes found");
          }
        }      
        else {
          console.log("No matching surveyfound");
          return;
        }
      }
      const URL = `${baseURL}/api/Survey/AddSurvey?teamnName=${
        JSON.parse(Cookies.get("seletedTeam")).name
      }`;
      console.log("Now printing formData");
      console.log(formData);


      try{
        const response = await postrequest(URL, formData);
        displayAlert(response.data.message);
        cancelClick();
      }
      catch(error) {
        console.log("errrorrr");
        console.log(error);
        navigate("/service-problem");
      };
    }
  };

  const handleInputChange = (value, e) => {
    const { name } = e.target;
    setSurveydetails({
      ...surveydetails,
      [name]: value,
    });
  };

  const handleDropdownChange = (selectedOption) => {
    if(selectedOption){
      setSelectedDropdownValue(selectedOption);
      console.log("selectedOption  ", selectedOption);
    }
    else{
      setSelectedDropdownValue(null);
    }
    
  };

  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 20000 }
    );
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Button onClick={cancelClick} kind="text" className="back-button">
        Back
      </Button>

      <Panel title="Enter Questionnaire Details">
        <Input
          required
          id="surveyName"
          title="Questionnaire Name"
          name="surveyName"
          value={surveydetails.surveyName}
          onChange={handleInputChange}
          className="mb-md-3"
          error={errors.surveyNameError}
          errorNode={
            errors.surveyNameError && (
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a survey name
              </div>
            )
          }
        />

        <TextArea
          required
          id="surveyDescription"
          title="Questionnaire Description"
          name="surveyDescription"
          value={surveydetails.surveyDescription}
          onChange={handleInputChange}
          className="mb-md-3"
        />

        {errors.surveyDescriptionError && (
          <div
            id="errormessage"
            aria-live="polite"
            className="ap-field-email-validation-error"
          >
            Please enter a survey description
          </div>
        )}

        <Upload
          onChange={fileHandler}
          multiple={false}
          autoUpload={false}
          acceptFileType=".XLSX"
          uploadInstruction="You can upload Excel files. The max file size is 10mb."
          uploadTitle="Upload your file"
          maxFileSize={10 * 1024 * 1024}
          config={{
            trigger: false,
            type: "inline",
            size: true,
          }}
        />
        {errors.fileError && (
          <div className="ap-field-email-validation-error">
            Please upload a file
          </div>
        )}
        <div className='qDropdown'>
          <p className='qDropdown1'>Select questionnaires</p>
          <Select className='qDropdown2' searchable data={filteredItems.map(name => ({label: name,value:name}))} 
          onSelect={(selectedOption) => handleDropdownChange(selectedOption)}></Select>
        </div>

        <CalendarPicker
          placeholder="DD/MM/YYYY"
          locale="en"
          fieldTitle="Expiry date"
          minDate={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1
            )
          }
          onChange={(date) =>
            setSurveydetails({
              ...surveydetails,
              expiryDate: date,
            })
          }
          format="DD/MM/YYYY"
          value={surveydetails.expiryDate}
          disabled={isEdit}
          className="mb-md-3"
        />

        <Button kind="primary" type="submit" onClick={submitSurvey}>
          Save
        </Button>

      </Panel>
    </div>
  );
}

export default UploadSurvey;

// export default UploadSurvey\



